<template>
  <div class="categories page"> 
    <div class="header">
      <a @click="$router.go(-1)" class="go-back"><i class="fas fa-chevron-left"></i></a>
      <span class="header__title">Категории</span>
      <div class="header__right search" :class="{active: activeSearch}">
        <button class="btn persons__header-btn search-button" @click="search"><i class="fas fa-search"></i></button>
        <input type="text" class="search-input" ref="search" v-model="searchValue">
        <button class="btn persons__header-btn search-close" @click="search"><i class="fas fa-times"></i></button>  
      </div>
    </div>
    <div class="container">
      <div class="categories-list">
        <router-link to="/favorite-persons" class="category-link">
          <div class="category">
            <div class="category__image">
              <img src="@/assets/images/favorite-icon.png" alt="">
            </div>
            <div class="category__info">
              <span class="category__name">Избранное</span>
              <span class="category__count" v-if="wishlistCount">{{wishlistCount}}</span>
              <span class="category__count" v-else>0</span>
            </div>
          </div>
        </router-link>
        <router-link to="/persons" class="category-link" v-for="category in categories">
          <div class="category" @click="$emit('openCategory', category)">
            <div class="category__image">
              <img :src="category.img" alt="">
            </div>
            <div class="category__info">
              <span class="category__name">{{category.name}}</span>
              <span class="category__count">{{category.count}}</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Categories',
  data () {
    return {
      categories: null,
      wishlistCount: null,
      activeSearch: false,
      searchValue: '',
    }
  },
  methods: {
    search() {
      if(!this.activeSearch) {
        this.activeSearch = true
        this.$refs.search.focus();
      } else {
        this.activeSearch = false
      }
    }
  },
  mounted() {
    fetch('https://person.lezgikim.ru/api.php?do=get%D0%A1ategories&key=156dfdd335hjkodS')
    .then(response => response.json())
    .then(json => {
      this.categories = json
    })

    if (localStorage.getItem('wishlist')) {
      var wishlistCount = JSON.parse(localStorage.getItem('wishlist'));
      this.wishlistCount = wishlistCount.length
    }
  }
}
</script>

<style lang="scss">
  @import "../assets/css/categories.scss";
</style>



